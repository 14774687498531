import { Platform } from 'react-native';

const backdrop = {
    backgroundColor: '#000',
    opacity: 0.5,
};

const breakpoints = {
    xSmall: { max: 320 },
    small: { min: 321, max: 719 },
    medium: { min: 720, max: 1023 },
    large: { min: 1024, max: 1439 },
    xLarge: { min: 1440 },
};

const white = '#ffffff';
const grey = {
    50: '#f7f7f7',
    100: '#f2f2f2',
    200: '#e9e9e9',
    300: '#cccccc',
    400: '#a0a0a0',
    500: '#888888',
    600: '#666666',
    700: '#444444',
    800: '#222222',
    900: '#111111', // less common
};
const blue = {
    50: '#e5f7ff',
    100: '#d8f3ff',
    200: '#b8e1f6',
    300: '#9acfef',
    400: '#7ebce8',
    500: '#63aae1',
    600: '#4a98da',
    700: '#3286d2',
    800: '#1874cb',
    900: '#0d65b8',
};
const green = {
    50: '#e9f7e6',
    100: '#d6f3d1',
    200: '#bbdfb6',
    300: '#a1cc9c',
    400: '#88b883',
    500: '#71a66c',
    600: '#599455',
    700: '#438341',
    800: '#2c722e',
    900: '#246326',
};
const orange = {
    50: '#ffedd6',
    100: '#ffe0b9',
    200: '#fdd09d',
    300: '#fac082',
    400: '#f8ae6a',
    500: '#f59e53',
    600: '#f28c3e',
    700: '#f07b2a',
    800: '#ee6817',
    900: '#e35f10',
};
const red = {
    50: '#ffe9e3',
    100: '#ffe2da',
    200: '#fec6b7',
    300: '#faaa95',
    400: '#f58f76',
    500: '#ed7558',
    600: '#e35a3e',
    700: '#d83e25',
    800: '#cb1c0d',
    900: '#bf0f00',
};
const primaryUI = {
    grey0: white,
    grey15: grey[50],
    grey30: grey[100],
    grey45: grey[200],
    grey60: grey[300],
    grey75: grey[400],
    grey90: grey[600],
    grey100: grey[800],
    green: '#4c9e00',
    greenHover: '#6fb133',
    greenSecondaryHover: '#e9f3e0',
};
const brand = {
    barn: '#951b1e',
    denim: '#465f70',
    earth: '#482817',
    harvest: '#d47024',
    flaxen: '#e1b95f',
    leaf1: '#88d600',
    leaf2: primaryUI.green,
    sky: '#88ede2',
    twilight: '#226398',
    lightGrey1: '#f1f0f2',
    lightGrey2: '#dcdcdf',
    grey1: '#bebebe',
    grey2: '#88888d',
};
const categorical = {
    100: brand.twilight,
    200: '#61bea1',
    300: '#61d1e8',
    400: '#f7c960',
    500: brand.barn,
    600: brand.harvest,
};
const inlineNotification = {
    dangerText: '#701c0d',
    dangerBackground: '#f7c3ba',
    warningText: grey[800],
    warningBackground: '#fbe7b2',
    successText: '#245300',
    successBackground: '#cfe1c0',
    infoText: '#003c6a',
    infoBackground: '#b2cde1',
};
const alert = {
    danger: '#cc280a',
    warning: '#f2b200',
    success: '#619b2d',
    info: '#0093e5',
};
const colors = {
    primary: brand.leaf2,
    primaryHover: primaryUI.greenHover,
    secondary: brand.denim,
    secondaryHover: '#476880',
    white,
    black: grey[800],
    grey,
    gray: grey,
    blue,
    green,
    orange,
    red,
    primaryUI,
    brand,
    categorical,
    inlineNotification,
    alert,
    // TODO: remove grey00 - grey5 in future batched breaking changes
    grey00: grey[50],
    grey0: grey[100],
    grey1: grey[200],
    grey2: grey[300],
    grey3: grey[400],
    grey4: grey[600],
    grey5: grey[800],
    greyOutline: grey[300],
    searchBg: white,
    success: brand.leaf2,
    successBg: inlineNotification.successBackground,
    error: alert.danger,
    warning: alert.warning,
    disabled: grey[400],
    divider: grey[300],
    transparent: 'transparent',
    info: {
        text: inlineNotification.infoText,
        background: inlineNotification.infoBackground,
    },
    danger: {
        text: inlineNotification.dangerText,
        background: inlineNotification.dangerBackground,
    },
    warningBackground: inlineNotification.warningBackground,
    selected: '#edf3f7',
};

const typography = {
    fontFamily: {
        regular: 'OpenSans_400Regular',
        semiBold: 'OpenSans_600SemiBold',
    },
    fontSize: {
        xSmall: 12,
        small: 14,
        medium: 16,
        large: 18,
        xLarge: 21,
        xxLarge: 26,
        hero: 105,
    },
    lineHeight: {
        xxSmall: 16,
        xSmall: 18,
        small: 20,
        medium: 24,
        large: 28,
        xLarge: 32,
        hero: 128,
    },
};

const webDepth = {
    penumbra: [
        '0px 0px 0px 0px',
        '0px 1px 1px 0px',
        '0px 2px 2px 0px',
        '0px 3px 4px 0px',
        '0px 4px 5px 0px',
        '0px 5px 8px 0px',
        '0px 6px 10px 0px',
        '0px 7px 10px 1px',
        '0px 8px 10px 1px',
        '0px 9px 12px 1px',
        '0px 10px 14px 1px',
        '0px 11px 15px 1px',
        '0px 12px 17px 2px',
        '0px 13px 19px 2px',
        '0px 14px 21px 2px',
        '0px 15px 22px 2px',
        '0px 16px 24px 2px',
        '0px 17px 26px 2px',
        '0px 18px 28px 2px',
        '0px 19px 29px 2px',
        '0px 20px 31px 3px',
        '0px 21px 33px 3px',
        '0px 22px 35px 3px',
        '0px 23px 36px 3px',
        '0px 24px 38px 3px',
    ],
};
const derive = (i, a, b, a2, b2) => {
    return ((i - a) * (b2 - a2)) / (b - a) + a2;
};
const parseShadow = (raw) => {
    const values = raw.split(' ').map((val) => +val.replace('px', ''));
    return {
        x: values[0],
        y: values[1],
        blur: values[2],
        spread: values[3],
    };
};
const maxElevation = 24;
const generateElevationStyle = (depth = 0) => {
    let style = {};
    if (Platform.OS === 'android') {
        style = {
            elevation: depth,
        };
    }
    else {
        const s = parseShadow(webDepth.penumbra[depth]);
        style = {
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 0,
            },
            shadowOpacity: depth <= 0 ? 0 : Number(derive(depth - 1, 1, 24, 0.2, 0.6).toFixed(2)),
            shadowRadius: Number(derive(s.blur, 1, 38, 1, 16).toFixed(2)),
        };
    }
    return style;
};
/**
 * React-Native cross-platform shadow/elevations inspired by: https://github.com/naeemur/react-native-elevation#readme
 * Modified to have an evenly centered shadow on ios and web.
 * Usage:
 * ```jsx
 * <View style={[{ width: 50, height: 50 }, theme.shadow[2]]} />
 * ```
 */
const elevations = new Array(maxElevation + 1)
    .fill(undefined)
    .map((x, index) => generateElevationStyle(index));
elevations.interpolate = (animatedValue, { inputRange, outputRange, ...opts }) => {
    let style = {};
    if (Platform.OS === 'android') {
        style = {
            elevation: animatedValue.interpolate({
                inputRange,
                outputRange,
                ...opts,
            }),
        };
    }
    else {
        const output = {
            height: [],
            shadowOpacity: [],
            shadowRadius: [],
        };
        outputRange.forEach((depth) => {
            const s = parseShadow(webDepth.penumbra[depth]);
            const y = s.y === 1 ? 1 : Math.floor(s.y * 0.5);
            output.height.push(y);
            output.shadowOpacity.push(depth <= 0 ? 0 : Number(derive(depth - 1, 1, 24, 0.2, 0.6).toFixed(2)));
            output.shadowRadius.push(Number(derive(s.blur, 1, 38, 1, 16).toFixed(2)));
        });
        style = {
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: animatedValue.interpolate({
                    inputRange,
                    outputRange: output.height,
                    ...opts,
                }),
            },
            shadowOpacity: animatedValue.interpolate({
                inputRange,
                outputRange: output.shadowOpacity,
                ...opts,
            }),
            shadowRadius: animatedValue.interpolate({
                inputRange,
                outputRange: output.shadowRadius,
                ...opts,
            }),
        };
    }
    return style;
};

const shape = { borderRadius: 4 };

const spacing = (n) => n * 8;

const Badge = {
    defaultProps: {
        containerStyle: {
            position: 'absolute',
            top: -9,
            left: 8,
        },
        badgeStyle: {
            minWidth: spacing(2),
            height: spacing(2),
            borderWidth: 0,
            backgroundColor: colors.error,
            borderRadius: 8,
        },
        textStyle: {
            color: colors.white,
            fontFamily: typography.fontFamily.semiBold,
            fontSize: typography.fontSize.xSmall,
            lineHeight: typography.lineHeight.xxSmall,
        },
    },
};

// text styles shared between text types
const body = {
    fontSize: typography.fontSize.medium,
    fontFamily: typography.fontFamily.regular,
    lineHeight: typography.lineHeight.medium,
};
const caption = {
    fontSize: typography.fontSize.xSmall,
    fontFamily: typography.fontFamily.regular,
    lineHeight: typography.lineHeight.xxSmall,
};
const subHeader = {
    fontSize: typography.fontSize.small,
    fontFamily: typography.fontFamily.regular,
    lineHeight: typography.lineHeight.small,
};
const mobile = {
    body,
    bodyBold: {
        ...body,
        fontFamily: typography.fontFamily.semiBold,
    },
    caption,
    captionBold: {
        ...caption,
        fontFamily: typography.fontFamily.semiBold,
    },
    h1: {
        fontSize: typography.fontSize.xLarge,
        fontFamily: typography.fontFamily.semiBold,
        lineHeight: typography.lineHeight.large,
    },
    h2: {
        fontSize: typography.fontSize.large,
        fontFamily: typography.fontFamily.semiBold,
        lineHeight: typography.lineHeight.large,
    },
    h3: {
        fontSize: typography.fontSize.large,
        fontFamily: typography.fontFamily.semiBold,
        lineHeight: typography.lineHeight.large,
    },
    h4: {
        fontSize: typography.fontSize.medium,
        fontFamily: typography.fontFamily.semiBold,
        lineHeight: typography.lineHeight.medium,
    },
    h5: {
        fontSize: typography.fontSize.small,
        fontFamily: typography.fontFamily.semiBold,
        lineHeight: typography.lineHeight.small,
    },
    hero: {
        fontSize: typography.fontSize.hero,
        fontFamily: typography.fontFamily.semiBold,
        lineHeight: typography.lineHeight.hero,
    },
    subHeader,
    subHeaderBold: {
        ...subHeader,
        fontFamily: typography.fontFamily.semiBold,
    },
};
const desktop = {
    ...mobile,
    h1: {
        ...mobile.h1,
        fontSize: typography.fontSize.xxLarge,
        lineHeight: typography.lineHeight.xLarge,
    },
    h2: {
        ...mobile.h2,
        fontSize: typography.fontSize.xLarge,
        lineHeight: typography.lineHeight.large,
    },
};
const Text = {
    defaultProps: {
        // TODO: remove when all Text occurrences are imported from bonsai-core
        h1Style: {
            fontSize: typography.fontSize.xxLarge,
        },
        h2Style: {
            fontSize: typography.fontSize.xLarge,
        },
        h3Style: {
            fontSize: typography.fontSize.large,
        },
        h4Style: {
            fontSize: typography.fontSize.medium,
        },
        style: {
            fontSize: typography.fontSize.medium,
        },
        // end TODO
        ...typography,
        mobile,
        desktop,
    },
};

const Button = {
    defaultProps: {
        buttonStyle: {
            borderRadius: shape.borderRadius,
            paddingHorizontal: 16,
            paddingVertical: 4,
        },
        titleStyle: {
            ...Text.defaultProps.mobile.bodyBold,
        },
        iconContainerStyle: {
            marginHorizontal: 0,
        },
        disabledStyle: {
            backgroundColor: colors.grey[300],
        },
        disabledTitleStyle: {
            color: colors.white,
        },
    },
};

const Header = {
    defaultProps: {
        backgroundColor: colors.brand.denim,
        barStyle: 'light-content',
        centerContainerStyle: { justifyContent: 'center' },
        leftComponent: { color: colors.white, style: { color: colors.white } },
        centerComponent: { color: colors.white, style: { color: colors.white } },
        rightComponent: { color: colors.white, style: { color: colors.white } },
    },
};

const Icon = {
    defaultProps: {
        type: 'material',
    },
};

const Input = {
    defaultProps: {
        inputContainerStyle: {
            borderRadius: 4,
            borderWidth: 2,
            borderColor: colors.greyOutline,
            paddingHorizontal: 8,
        },
        labelStyle: {
            color: colors.black,
            marginBottom: 4,
        },
    },
};

const theme = {
    backdrop,
    breakpoints,
    colors,
    components: {
        Badge,
        Button,
        Header,
        Icon,
        Input,
        Text,
        Snackbar: { defaultProps: {} },
    },
    // TODO: refactor Text theme usage so we can remove this
    Text: Text.defaultProps,
    shadow: elevations,
    shape,
    spacing,
    typography,
};

export default theme;
